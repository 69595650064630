// src/components/ContactInfo.js
import React from "react";
import { Card } from "react-bootstrap";

function ContactInfo({ email, phone, responseTime }) {
  return (
    <Card className="text-center my-5 p-4 bg-light">
      <Card.Body>
        <Card.Title as="h2">Direct Contact Information</Card.Title>
        <Card.Text>
          Email: <a href={`mailto:${email}`}>{email}</a>
        </Card.Text>
        <Card.Text>
          Phone: <a href={`tel:${phone}`}>{phone}</a>
        </Card.Text>
        <Card.Text>Expected Response Time: {responseTime}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ContactInfo;
