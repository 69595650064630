// src/pages/Contact.js
import React from "react";
import { Container } from "react-bootstrap";
import ContactForm from "../components/contact/ContactForm";
import ContactInfo from "../components/contact/ContactInfo";
import SocialMediaLinks from "../components/contact/SocialMediaLinks";

function Contact() {
  return (
    <Container className="my-5">
      <ContactForm />
      <ContactInfo
        email="info@vedanticsolutions.com"
        phone="123-456-7890"
        responseTime="24-48 hours"
      />
      <SocialMediaLinks />
    </Container>
  );
}

export default Contact;
