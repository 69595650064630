// src/components/HeroSection.js
import React from "react";
import { Container, Button } from "react-bootstrap";

function HeroSection() {
  return (
    <Container className="text-center bg-light p-5">
      <h1 className="display-4">Proudly Serving as an SDVOSB</h1>
      <p className="lead">
        Delivering excellence in government contracting with veteran dedication
        and integrity.
      </p>
      <Button href="/about" variant="primary" className="mt-3">
        Learn More About Us
      </Button>
    </Container>
  );
}

export default HeroSection;
