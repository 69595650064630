// src/components/Footer.js
import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer className="bg-dark text-white py-4 mt-5">
      <Container className="text-center">
        <p>
          &copy; {new Date().getFullYear()} Vedantic Solutions LLC. All rights
          reserved.
        </p>
      </Container>
    </footer>
  );
}

export default Footer;
