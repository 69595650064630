// src/components/SDVOSBValues.js
import React from "react";
import { Card, ListGroup } from "react-bootstrap";

function SDVOSBValues() {
  const values = [
    {
      title: "Dedication",
      description:
        "Our veteran-owned status reflects our commitment to excellence and service.",
    },
    {
      title: "Leadership",
      description:
        "With experience in leadership roles, we bring structured and effective solutions.",
    },
    {
      title: "Resilience",
      description:
        "Our team's resilience ensures we overcome challenges to meet project goals.",
    },
    {
      title: "Integrity",
      description:
        "We uphold the highest standards of honesty and transparency in all projects.",
    },
  ];

  return (
    <Card className="text-center my-5 p-4">
      <Card.Body>
        <Card.Title as="h2">Our Values</Card.Title>
        <Card.Text>
          As a Service-Disabled Veteran-Owned Small Business, we are driven by
          values that define our mission and guide our work.
        </Card.Text>
        <ListGroup variant="flush">
          {values.map((value, index) => (
            <ListGroup.Item key={index}>
              <h5>{value.title}</h5>
              <p>{value.description}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default SDVOSBValues;
