// src/components/Certifications.js
import React from "react";
import { Card } from "react-bootstrap";

function Certifications() {
  return (
    <Card className="text-center my-5 p-4 bg-light">
      <Card.Body>
        <Card.Title as="h2">Certifications & Qualifications</Card.Title>
        <Card.Text>
          Proudly certified as a Service-Disabled Veteran-Owned Small Business
          (SDVOSB). We meet and exceed government standards for contracting
          services.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Certifications;
