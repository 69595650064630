// src/components/ServiceDescriptions.js
import React from "react";
import { Row, Col, Card } from "react-bootstrap";

function ServiceDescriptions() {
  const services = [
    {
      title: "Government Contracting",
      summary:
        "End-to-end contracting services tailored for government clients.",
      scope:
        "Comprehensive project management, compliance with federal standards, and secure project delivery.",
      benefits:
        "Reliability, transparency, and SDVOSB certification for trustworthy partnerships.",
    },
    {
      title: "Consulting Services",
      summary:
        "Strategic consulting for efficient and effective project outcomes.",
      scope:
        "Includes risk management, process optimization, and performance assessment.",
      benefits:
        "Enhanced operational efficiency and cost-effective solutions for government agencies.",
    },
    {
      title: "IT and Tech Solutions",
      summary:
        "Robust IT solutions designed to meet the unique needs of government projects.",
      scope:
        "Cybersecurity, infrastructure management, and secure data handling.",
      benefits:
        "Ensures compliance with government cybersecurity standards and reliable infrastructure.",
    },
  ];

  return (
    <Row className="my-5">
      {services.map((service, index) => (
        <Col key={index} md={4} className="mb-4">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>{service.title}</Card.Title>
              <Card.Text>
                <strong>Summary:</strong> {service.summary}
              </Card.Text>
              <Card.Text>
                <strong>Scope:</strong> {service.scope}
              </Card.Text>
              <Card.Text>
                <strong>Benefits:</strong> {service.benefits}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default ServiceDescriptions;
