// src/components/ServicesGrid.js
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaRegBuilding, FaHandsHelping, FaLaptopCode } from "react-icons/fa";

function ServicesGrid() {
  const services = [
    {
      icon: <FaRegBuilding />,
      title: "Government Contracting",
      description: "Specialized solutions tailored to government needs.",
    },
    {
      icon: <FaHandsHelping />,
      title: "Consulting Services",
      description: "Professional consulting for efficient project management.",
    },
    {
      icon: <FaLaptopCode />,
      title: "IT and Tech Solutions",
      description: "Reliable IT solutions for complex government projects.",
    },
  ];

  return (
    <Row className="text-center my-5">
      {services.map((service, index) => (
        <Col key={index} md={4} className="mb-4">
          <Card className="h-100">
            <Card.Body>
              <div className="mb-3" style={{ fontSize: "2rem" }}>
                {service.icon}
              </div>
              <Card.Title>{service.title}</Card.Title>
              <Card.Text>{service.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default ServicesGrid;
