// src/components/ContactForm.js
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Modal from "../modal/modal";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    email: "",
    serviceType: "",
    comments: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://a366srddk7.execute-api.us-east-1.amazonaws.com/SendEmails",
        {
          // Replace with your actual API Gateway endpoint
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setModalContent({
          title: "Success",
          message: "Your email has been sent successfully!",
        });
        setIsModalOpen(true);
        setFormData({
          name: "",
          organization: "",
          email: "",
          serviceType: "",
          comments: "",
        });

        // Optionally, display a success message to the user
      } else {
        setModalContent({
          title: "Error",
          message:
            "There was an error sending your email. Please try again later.",
        });
        setIsModalOpen(true);
        // Optionally, display an error message to the user
      }
    } catch (error) {
      setModalContent({
        title: "Error",
        message:
          "There was an error sending your email. Please check your internet connection and try again.",
      });
      setIsModalOpen(true);
      // Optionally, display a network error message to the user
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="my-5">
        <h2>Contact Us</h2>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />
        </Form.Group>

        <Form.Group controlId="organization">
          <Form.Label>Organization</Form.Label>
          <Form.Control
            type="text"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            placeholder="Enter your organization"
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
        </Form.Group>

        <Form.Group controlId="serviceType">
          <Form.Label>Service Type</Form.Label>
          <Form.Control
            as="select"
            name="serviceType"
            value={formData.serviceType}
            onChange={handleChange}
            required
          >
            <option value="">Select a service</option>
            <option value="governmentContracting">
              Government Contracting
            </option>
            <option value="consulting">Consulting</option>
            <option value="itSolutions">IT Solutions</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="comments">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            rows={3}
            placeholder="Additional comments"
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        message={modalContent.message}
      />
    </div>
  );
}

export default ContactForm;
