// src/components/SocialMediaLinks.js
import React from "react";
import { Card } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";

function SocialMediaLinks() {
  return (
    <Card className="text-center my-5 p-4 bg-light">
      <Card.Body>
        <Card.Title as="h2">Connect with Us</Card.Title>
        <Card.Text>
          Follow us on LinkedIn for updates and industry insights.
        </Card.Text>
        <a
          href="https://www.linkedin.com/company/vedantic-solutions"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin size={40} color="#0077b5" />
        </a>
      </Card.Body>
    </Card>
  );
}

export default SocialMediaLinks;
