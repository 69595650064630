// src/pages/About.js
import React from "react";
import { Container } from "react-bootstrap";
import FoundersStory from "../components/about/FoundersStory";
import SDVOSBValues from "../components/about/SDVOSBValues";
import TeamProfiles from "../components/about/TeamProfiles";

function About() {
  return (
    <Container className="my-5">
      <FoundersStory />
      <SDVOSBValues />
      <TeamProfiles />
    </Container>
  );
}

export default About;
