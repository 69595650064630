// src/components/FoundersStory.js
import React from "react";
import { Card } from "react-bootstrap";

function FoundersStory() {
  return (
    <Card className="text-center my-5 p-4 bg-light">
      <Card.Body>
        <Card.Title as="h2">Our Founder's Story</Card.Title>
        <Card.Text>
          After years of service in the armed forces, our founder launched
          Vedantic Solutions with a mission to bring the values of dedication,
          leadership, and resilience to the field of government contracting.
        </Card.Text>
        <Card.Text>
          "At Vedantic Solutions, we strive to exceed expectations, knowing that
          our work has the power to make a meaningful impact on government
          projects and public service. We are committed to integrity, quality,
          and the highest standards of professionalism."
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FoundersStory;
