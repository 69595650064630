// src/components/TeamProfiles.js
import React from "react";
import { Row, Col, Card } from "react-bootstrap";

function TeamProfiles() {
  const teamMembers = [
    {
      name: "Kyle Joshi",
      role: "President",
      description:
        "Experienced in overseeing government contracts with a focus on compliance and timely delivery.",
      certifications: ["AWS Solutions Architect", "Secret Clearance (Lapsed)"],
      education: [
        "MS Computer Science JHU",
        "MS Systems Engineering JHU",
        "ORSAMAC Army Logistics University",
      ],
    },
    // {
    //   name: "Edward Poon",
    //   role: "Lead Engineer",
    //   description: "Really, really good engineer.",
    //   certifications: ["AWS Solutions Architect"],
    //   education: ["The Streets"],
    // },
  ];

  return (
    <Row className="my-5">
      <h2 className="text-center w-100">Meet Our Team</h2>
      {teamMembers.map((member, index) => (
        <Col key={index} md={4} className="mb-4">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>{member.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {member.role}
              </Card.Subtitle>
              <Card.Text>{member.description}</Card.Text>
              <Card.Text>
                <strong>Certifications:</strong>{" "}
                {member.certifications.join(", ")}
              </Card.Text>
              <Card.Text>
                <strong>Education:</strong> {member.education.join(", ")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default TeamProfiles;
