// src/components/CaseStudies.js
import React from "react";
import { Row, Col, Card } from "react-bootstrap";

function CaseStudies() {
  const caseStudies = [
    {
      title: "Project Alpha",
      description:
        "Provided IT solutions for a federal agency, ensuring secure data handling and infrastructure compliance.",
      result: "Improved data security and compliance with federal standards.",
    },
    {
      title: "Logistics Optimization for Defense",
      description:
        "Streamlined logistics for a government defense project, reducing operational costs and enhancing efficiency.",
      result: "Reduced delivery times by 25% and cut costs by 15%.",
    },
    {
      title: "Healthcare IT Implementation",
      description:
        "Developed and deployed a secure healthcare information system for a state government project.",
      result:
        "Enhanced patient data security and compliance with healthcare regulations.",
    },
  ];

  return (
    <Row className="my-5">
      <h2 className="text-center w-100">Previous Projects</h2>
      {caseStudies.map((caseStudy, index) => (
        <Col key={index} md={4} className="mb-4">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>{caseStudy.title}</Card.Title>
              <Card.Text>{caseStudy.description}</Card.Text>
              <Card.Text>
                <strong>Result:</strong> {caseStudy.result}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default CaseStudies;
