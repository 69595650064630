// src/components/CTA.js
import React from "react";
import { Button } from "react-bootstrap";

function CTA({ label, link }) {
  return (
    <div className="text-center my-3">
      <Button href={link} variant="success" size="lg">
        {label}
      </Button>
    </div>
  );
}

export default CTA;
